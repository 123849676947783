import React from 'react';
import { usePriceFeedData } from "../hooks/usePriceFeedData"
import { GoTriangleUp, GoTriangleDown } from "react-icons/go";

const SharePriceWidget = ({pageType}) => {
  
  const priceFeedData = usePriceFeedData()
  const priceData = [...priceFeedData.allPriceFeedData.nodes]
  const current = priceData.filter(item => item.dataId === "current")[0].data;
  const currency = priceData.filter(item => item.dataId === "currency")[0].data;
  const change = priceData.filter(item => item.dataId === "val_change")[0].data;
  const signum = change > 0 ? '+' : ''
  const isPositive = change > 0
  
  console.log('Price Feed Timestamp:', priceData[17].data)

  return (
    <div
      className={`flex items-center pr-4 font-bold text-body-base-sm ${
        pageType === "homepage" || pageType === "article" ? "text-white" : "text-grey-75"
      }`}
    >
      {current} {currency}
      {isPositive
        ? <GoTriangleUp className="w-3 h-3 ml-2 mr-1" />
        : <GoTriangleDown className="w-3 h-3 ml-2 mr-1" />
      }
      {signum}{change}
    </div>
  )
}

export default SharePriceWidget