import React from "react"
import { Link } from "gatsby"

const isBrowser = typeof window !== "undefined"

const Button = props => {
  const { type, url, target, copy, children, extraClasses, ...rest } = props

  // console.log(props)

  const checkIsInternal = url => {
    if (isBrowser) {
      // If it's a relative url such as '/path', 'path' and does not contain a protocol we can assume it is internal.
      if (url.indexOf("://") === -1) return true

      const currentHostname =
        typeof window !== "undefined" ? window.location.href : ""
      return currentHostname === url.hostname
    }
  }

  const buttonType = {
    primary: "btn text-white border-green-100 bg-green-100",
    tertiary: "btn text-grey-dark border-grey-dark",
    textLink: "btn-link inline-block text-green-100",
  }

  const buttonAnimation = {
    primary: "duration-500 ease-in-out hover:bg-white hover:text-green-100",
    tertiary: "duration-500 ease-in-out hover:bg-grey-dark hover:text-white",
    textLink: "",
  }

  const isInternal = checkIsInternal(url)

  // console.log(target)

  if (!isInternal || (target === "_blank" && target !== "_self")) {
    return (
      <a
        className={`${buttonType[type]} ${buttonAnimation[type]} ${extraClasses}`}
        href={url}
        target="_blank"
        rel="noreferrer"
        {...rest}
      >
        <span>{copy}</span>
        {children}
      </a>
    )
  } else {
    return (
      <Link
        className={`${buttonType[type]} ${buttonAnimation[type]} ${extraClasses}`}
        to={url}
        target={target}
        {...rest}
      >
        <span>{copy}</span>
        {children}
      </Link>
    )
  }
}

export default Button
