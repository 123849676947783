/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = props => {
  const seoQuery = useStaticQuery(
    graphql`
      {
        allPrismicGlobalSeo {
          edges {
            node {
              data {
                body {
                  ... on PrismicGlobalSeoDataBodyMetadata {
                    primary {
                      content_type
                      description
                      og_site_name
                      og_title
                      og_description
                      og_image {
                        url
                      }
                      og_image_alt
                      og_url {
                        url
                      }
                      twitter_card
                      twitter_image {
                        url
                      }
                      twitter_image_alt
                      twitter_site
                      twitter_creator
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  // console.log(props)

  const meta = []

  const seoData = seoQuery.allPrismicGlobalSeo.edges[0].node.data.body[0].primary
  // console.log(seoData.og_title)

  const seo = {
    title: props.title,
    description: props.seo ? props.seo.description || seoData.description : seoData.description,
    // keywords: props.seo ? props.seo.keywords || seoData.keywords : seoData.keywords,
    ogSiteName: seoData.og_site_name,
    ogType: seoData.content_type,
    ogTitle: props.seo ? props.seo.og_title || seoData.og_title : props.title,
    ogDescription: props.seo ? props.seo.og_description || seoData.og_description : seoData.og_description,
    ogImage: props.seo ? props.seo.og_image.url || seoData.og_image.url : seoData.og_image.url,
    ogImageAlt: props.seo ? props.seo.og_image_alt || seoData.og_image_alt : seoData.og_image_alt,
    ogUrl: props.url,
    twitterCard: seoData.twitter_card,
    twitterImage: props.seo ? props.seo.twitter_image.url || seoData.twitter_image.url : seoData.twitter_image.url,
    twitterImageAlt: props.seo ? props.seo.twitter_image_alt || seoData.twitter_image_alt : seoData.twitter_image_alt,
    twitterSite: seoData.twitter_site,
    twitterCreator: seoData.twitter_creator,
  }

  // console.log('seo og image alt', seo.ogImageAlt)

  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      // title={`${seo.title} | ${seo.ogTitle}`}
      title={`${seo.title}`}
      titleTemplate={seo.ogSiteName ? `%s | ${seo.ogSiteName}` : null}
      // script={[
      //   {
      //     "type": "text/javascript",
      //     "src": "https://cdn-ukwest.onetrust.com/consent/49fc935a-b613-4aaa-afc8-4f3a1596d2ae/OtAutoBlock.js",
      //   },
      //   {
      //     "type": "text/javascript",
      //     "charset": "UTF-8",
      //     "src": "https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js", 
      //     "data-document-language": "true",
      //     "data-domain-script": "49fc935a-b613-4aaa-afc8-4f3a1596d2ae"
      //   },
      //   {
      //     "type": "text/javascript",
      //     innerHTML: 'function OptanonWrapper() {var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="bM0bFwux8TeWM7e6q98mxsQlBKc4d1MM";;analytics.SNIPPET_VERSION="4.15.3";analytics.load("bM0bFwux8TeWM7e6q98mxsQlBKc4d1MM");analytics.page();};}',
      //   },
      // ]}
      meta={[
        {
          name: "description",
          content: seo.description,
        },
        // {
        //   name: "keywords",
        //   content: seo.keywords,
        // },
        {
          property: "og:site_name",
          content: seo.ogSiteName,
        },
        {
          property: "og:title",
          content: seo.ogTitle,
        },
        {
          property: "og:description",
          content: seo.ogDescription,
        },
        {
          property: "og:image",
          content: seo.ogImage,
        },
        {
          name: "og:image:alt",
          content: seo.ogImageAlt,
        },
        {
          property: "og:type",
          content: seo.ogType,
        },
        {
          property: "og:url",
          content: seo.ogUrl,
        },
        {
          name: "twitter:card",
          content: seo.twitterCard,
        },
        {
          name: "twitter:image",
          content: seo.twitterImage,
        },
        {
          name: "twitter:image:alt",
          content: seo.twitterImageAlt,
        },
        {
          name: "twitter:site",
          content: seo.twitterSite,
        },
        {
          name: "twitter:creator",
          content: seo.twitterCreator,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: "en",
  meta: [],
  description: "",
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
