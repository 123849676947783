import React, { useState } from "react"
import classNames from "classnames"
import { useStaticQuery, graphql, Link } from "gatsby"
import { FiMenu, FiX, FiExternalLink } from "react-icons/fi"
import HeaderTop from "./HeaderTop"
import Button from "./Button"
import useWindowSize from "../hooks/useWindowSize"

const query = graphql`
  {
    prismicHeader {
      data {
        logo_white {
          url
          alt
          dimensions {
            width
            height
          }
        }
        logo_colour {
          url
          dimensions {
            width
            height
          }
        }
        external_link {
          target
          url
        }
        external_link_copy {
          text
        }
        menu_items {
          link {
            uid
            type
            url
          }
          link_copy {
            text
          }
        }
      }
    }
  }
`

const Header = ({ pageType }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const size = useWindowSize()

  const data = useStaticQuery(query)
  const {
    logo_white,
    logo_colour,
    external_link,
    external_link_copy,
    menu_items,
  } = data.prismicHeader.data


  // console.log('pageType', pageType)

  const logo =
    (pageType === "homepage" || pageType === "article") && size.width >= 1280 ? logo_white : logo_colour

  const headerClass = classNames(
    "z-20 w-screen bg-transparent shadow-menu xl:shadow-none",
    {
      "xl:absolute": pageType === "homepage" || pageType === "article" ,
    }
  )

  const linkClass = classNames(
    "my-3 xl:my-0 xl:py-0 xl:ml-6 font-bold no-underline duration-500 ease-in-out border-b-2",
    {
      "text-grey-100 xl:text-white": pageType === "homepage" || pageType === "article",
      "text-grey-100": pageType !== "homepage" || pageType === "article",
    }
  )

  const linkActiveClass = classNames("active")

  // console.log('menu_items', menu_items);

  // console.log("external_link", external_link)

  return (
    <header className={headerClass}>
      <HeaderTop
        pageType={pageType}
        externalLink={external_link}
        externalLinkCopy={external_link_copy}
      />
      <div className="flex flex-col items-center justify-between px-4 py-3 xl:py-6 xl:px-12 container-fluid xl:flex-row">
        <div className="flex justify-between w-full xl:w-auto">
          <div className="w-117 xl:w-146">
            <Link to="/">
              <img
                src={logo.url}
                alt="Pod Point Investors"
                width={logo.dimensions.width}
                height={logo.dimensions.height}
              />
            </Link>
          </div>

          <div
            className="flex items-center cursor-pointer xl:hidden"
            onClick={() => setMenuOpen(!menuOpen)}
            role="button"
            tabIndex="0"
          >
            {!menuOpen ? (
              <FiMenu className="w-6 h-6" />
            ) : (
              <FiX className="w-6 h-6" />
            )}
          </div>
        </div>

        <nav
          className={
            "flex-grow items-center xl:pt-0 xl:flex" +
            (menuOpen ? " flex" : " hidden")
          }
        >
          <div className="flex flex-col py-3 text-center xl:flex-row xl:ml-auto xl:py-0">
            {menu_items.map((item, index) => {
              const url = item.link.url
              const uid = item.link.uid
              const text = item.link_copy.text
              const menuUrl = uid != null ? `/${uid}` : url

              // console.log('menuUrl', menuUrl)

              return (
                <Link
                  to={menuUrl}
                  key={index}
                  className={linkClass}
                  activeClassName={linkActiveClass}
                >
                  {text}
                </Link>
              )
            })}

            <Button
              url={external_link.url}
              target="_blank"
              copy={external_link_copy.text}
              type="primary"
              extraClasses="xl:hidden mt-2"
            >
              <FiExternalLink className="inline-block w-6 h-6 ml-2 " />
            </Button>
          </div>
        </nav>
      </div>
    </header>
  )
}

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default Header
