/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Header from "./Header"
import Footer from "./Footer"
import CookieNotice from "./CookieNotice"

const Layout = ({ pageType, children, uid }) => {
  // console.log("pageType", pageType))

  const layoutClass = classNames(pageType, uid, {
    "flex flex-col h-screen":
      pageType === "404" || pageType === "news" || pageType === "documents",
  })

  return (
    <div className={layoutClass}>
      <Header pageType={pageType} />
      <main className="flex-auto">{children}</main>
      <Footer />
      <CookieNotice />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
