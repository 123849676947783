import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
// import PropTypes from "prop-types"
import cookieNotice from "@pod-point/cookie-notice.js"

const isBrowser = typeof window !== "undefined"

const CookieNotice = () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicCookieNotice {
        edges {
          node {
            data {
              copy {
                html
              }
              button_copy {
                text
              }
            }
          }
        }
      }
    }
  `)

  const [updateNow, setUpdateNow] = useState(true)

  const cookieNoticeText = data.allPrismicCookieNotice.edges[0].node.data.copy.html
  const cookieButtonText = data.allPrismicCookieNotice.edges[0].node.data.button_copy.text

  if (isBrowser) {
    if (cookieNotice.hasCookie()) {
      return null
    }
  }

  const forceUpdate = () => setUpdateNow(!updateNow)

  return (
    <div className="fixed bottom-0 z-50 w-full text-white bg-grey-dark cookie-wrapper">
      <div className="container-fluid cookie-container">
        <div className="py-6 md:flex md:items-center cookie">
          <div className="flex-1">
            <div
              className="cookie__notice body-small"
              dangerouslySetInnerHTML={{
                __html: cookieNoticeText,
              }}
            />
          </div>
          <div className="mt-4 md:mt-0 md:ml-6">
            <button
              className="text-white duration-500 ease-in-out border-grey-75 bg-grey-75 btn hover:bg-grey-dark hover:text-white"
              type="button"
              onClick={() => {
                cookieNotice.setCookie()
                forceUpdate()
              }}
            >
              {cookieButtonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

// CookieNotice.propTypes = {}

export default CookieNotice
