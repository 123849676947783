import { useStaticQuery, graphql } from "gatsby"

export const usePriceFeedData = () => {
  const priceFeedDataQuery = useStaticQuery(graphql`
    query PriceFeedData {
      allPriceFeedData {
        nodes {
          dataId
          data
          label
          type
        }
      }
    }
  `)

  return priceFeedDataQuery
}
