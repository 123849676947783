import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const query = graphql`
  {
    prismicFooter {
      data {
        copyright {
          text
        }
        footer_menu_items {
          link {
            uid
            url
            target
          }
          link_copy {
            text
          }
        }
      }
    }
  }
`

const Footer = () => {
  const data = useStaticQuery(query)
  const { copyright, footer_menu_items } = data.prismicFooter.data

  const checkIsInternal = url => {
    // If it's a relative url such as '/path', 'path' and does not contain a protocol we can assume it is internal.
    if (url.indexOf("://") === -1) return true

    const currentHostname =
      typeof window !== "undefined" ? window.location.href : ""
    return currentHostname === url.hostname
  }

  return (
    <footer className="py-6 lg:py-10 bg-grey-100">
      <div className="flex flex-col-reverse items-center justify-between xl:flex-row container-fluid ">
        <div className="mt-4 text-center text-white xl:text-left xl:mt-0">
          {copyright.text}
        </div>
        <nav className="flex flex-col items-center md:flex-row">
          {footer_menu_items.map((item, index) => {
            const url = item.link.url
            const uid = item.link.uid
            const target = item.link.target
            const text = item.link_copy.text
            const isInternal = checkIsInternal(url)

            if (isInternal) {
              return (
                <Link
                  to={`/${uid}`}
                  key={index}
                  className="mb-2 font-bold text-white no-underline duration-500 ease-in-out border-b-2 md:mb-0 md:mx-2 lg:ml-6 border-grey-100 hover:border-white"
                >
                  {text}
                </Link>
              )
            } else {
              return (
                <a
                  href={url}
                  target={target}
                  rel="noreferrer"
                  key={index}
                  className="mb-2 font-bold text-white no-underline duration-500 ease-in-out border-b-2 md:mb-0 md:mx-2 lg:ml-6 border-grey-100 hover:border-white"
                >
                  {text}
                </a>
              )
            }
          })}
        </nav>
      </div>
    </footer>
  )
}

export default Footer
