import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FiExternalLink } from "react-icons/fi"
import SharePriceWidget from "./SharePriceWidget"

const HeaderTop = ({ pageType, externalLink, externalLinkCopy }) => {

  const useApiQuery = useStaticQuery(graphql`
    {
      allPrismicGlobalSettings {
        nodes {
          data {
            use_api
          }
        }
      }
    }
  `)

  const useApi = useApiQuery.allPrismicGlobalSettings.nodes[0].data.use_api

  // console.log('useApi', useApiQuery.allPrismicGlobalSettings.nodes[0].data.use_api);

  return (
    <div
      className={`hidden xl:block h-10 ${
        pageType === "homepage" || pageType === "article" ? "shadow-header" : "bg-grey-25"
      }`}
    >
      <div className="flex justify-end container-fluid">
        {useApi && <SharePriceWidget pageType={pageType}/>}
        <a
          className="inline-block h-10 px-8 py-2 font-bold text-white no-underline bg-green-100 body-small"
          href={externalLink.url}
          target={externalLink.target}
          rel="noreferrer"
        >
          <span>{externalLinkCopy.text}</span>
          <FiExternalLink className="inline-block w-6 h-6 ml-2 " />
        </a>
      </div>
    </div>
  )
}

export default HeaderTop
